@font-face {
  font-family: "Roboto Condensed";
  src: url("/fonts/custom/RobotoCondensed-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("/fonts/custom/RobotoCondensed-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: italic;
}

.landingpage {
  font-family: "Roboto Condensed", sans-serif;
  --bs-body-font-family: "Roboto Condensed", sans-serif;
}
